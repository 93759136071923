import {useCallback, useEffect, useState} from 'react';
import {useOutlet} from 'reconnect.js';
import {aggregateConcessions, formatSessions} from '../Utils/Order';

export const useOrderList = () => {
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [actions] = useOutlet('actions');
  const [user] = useOutlet('user');

  const fetchOrderList = useCallback(async () => {
    try {
      setIsLoading(true);
      const resp = await actions.fetchOrderList();
      let result = resp.map((o) => {
        return {
          ...o,
          isFilmOrder: !!o.vista_order.Sessions,
          sessions: formatSessions(o.vista_order.Sessions, o.films_info?.[0]),
          aggregateConcessions: aggregateConcessions(o.vista_order.Concessions),
        };
      });
      setOrderList(result);
      return result;
    } catch (e) {
      console.log('debug', e);
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [actions]);

  useEffect(() => {
    if (!user) {
      return;
    }
    fetchOrderList().then(() => {});
  }, [fetchOrderList, user]);

  return {
    isLoading,
    orderList,
    fetchOrderList,
  };
};
