import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  Color,
  FILM_ORDER_STATUS_TYPE,
  FILM_ORDER_STATUS_TYPE_DISPLAY,
  ORDER_STATUS,
  ORDER_STATUS_DISPLAY,
  WALLET_TYPE,
  WALLET_TYPE_DISPLAY,
} from '../../constants';
import {BlockSelector} from '../../Components/BlockSelector';
import {Card} from '../../widget';
import {FilmProperty} from '../../Components/FilmProperty';
import {CalendarToday, LocalMovies, LocationOn} from '@styled-icons/material';
import moment from 'moment';
import {navigate} from 'gatsby-link';
import {useOrderList} from '../../Hooks/useOrderList';
import Spinner from '../../Components/Spinner';
import {MealItem} from '../../Components/MealItem';
import {withLoginRequired} from '../../HOC/withLoginRequired';
import {Selector} from './Selector';
import {Badge} from '../../Components/Badge';
import useModal from '../../Hooks/useModal';
import {ConfirmModalContent} from '../../Components/ConfirmModalContent';
import {getOrderSeatsInfo} from '../../Utils/Ticket';
import {isNotAllowViewDetail} from '../../Utils/Order';

const getMealOrderListByFilter = (orderList, activeFilter) => {
  const mealOrders = orderList.filter((o) => !o.isFilmOrder);

  if (activeFilter === 'unused') {
    return mealOrders.filter(
      (o) => o.is_pick_up === false && o.status === ORDER_STATUS.TICKET_CHECKIN,
    );
  } else if (activeFilter === 'used') {
    return mealOrders.filter(
      (o) => o.is_pick_up === true && o.status === ORDER_STATUS.TICKET_CHECKIN,
    );
  } else {
    return mealOrders.filter((o) => o.status !== ORDER_STATUS.TICKET_CHECKIN);
  }
};

const MealOrderList = (props) => {
  const {orderList} = props;
  const [active, setActive] = useState('unused');
  const options = [
    {name: '未使用', value: 'unused'},
    {name: '已使用', value: 'used'},
    {name: '其他', value: 'else'},
  ];
  return (
    <div className="view">
      <BlockSelector active={active} setActive={setActive} options={options} />
      {getMealOrderListByFilter(orderList, active).map((o, i) => {
        return (
          <MealItem
            order={o}
            key={i}
            onClick={async () => {
              if (isNotAllowViewDetail(o.status)) {
                return;
              }
              await navigate(`/meal-order?id=${o.id}`);
            }}
          />
        );
      })}
    </div>
  );
};

function FilmOrderList(props) {
  const {orderList} = props;
  const [activeFilmTicketType, setActiveFilmTicketType] = useState(
    FILM_ORDER_STATUS_TYPE.coming,
  );
  return (
    <div className="view">
      <BlockSelector
        active={activeFilmTicketType}
        setActive={setActiveFilmTicketType}
        options={FILM_ORDER_STATUS_TYPE_DISPLAY.map((f) => ({
          name: f.title,
          value: f.key,
        }))}
      />

      {orderList
        .filter((o) => o.isFilmOrder)
        .filter((o) => {
          const showTime = moment(o.sessions[0].showDateTime);
          const now = moment(new Date());
          return activeFilmTicketType === FILM_ORDER_STATUS_TYPE.started
            ? now.isAfter(showTime)
            : now.isBefore(showTime);
        })
        .map((order, i) => {
          const {sessions} = order;
          const targetSession = sessions[0];
          return (
            <StyledFilmOrderItem
              clickable
              key={i}
              onClick={async () => {
                if (isNotAllowViewDetail(order.status)) {
                  return;
                }
                await navigate(`/film-order?id=${order.id}`);
              }}>
              <img
                src={targetSession.film.poster}
                alt="poster"
                onError={(e) => {
                  e.target.src =
                    'https://fakeimg.pl/282x400/?text=404&font=lobster';
                }}
              />
              <div className="context">
                <div className="title">{targetSession.film.titleAlt}</div>
                <div className="subtitle">{targetSession.film.title}</div>
                <FilmProperty value={targetSession.film.filmPlayType}>
                  <LocalMovies size={12} />
                </FilmProperty>
                <FilmProperty
                  value={moment(targetSession.showDateTime).format(
                    'YYYY/MM/DD HH:mm',
                  )}>
                  <CalendarToday size={12} />
                </FilmProperty>
                <FilmProperty
                  value={`第${
                    targetSession.film.screenNumber
                  }廳 ${getOrderSeatsInfo(targetSession.tickets)}`}>
                  <LocationOn size={12} />
                </FilmProperty>
              </div>
              <Badge
                style={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  backgroundColor: ORDER_STATUS_DISPLAY[order.status].color,
                }}>
                {ORDER_STATUS_DISPLAY[order.status].title}
              </Badge>
            </StyledFilmOrderItem>
          );
        })}
    </div>
  );
}

const Wallet = (props) => {
  const [active, setActive] = useState(WALLET_TYPE.film);
  const {isLoading, orderList, fetchOrderList} = useOrderList();
  const {modalRef, modalElem} = useModal();

  useEffect(() => {
    const fetch = async () => {
      const orderList = await fetchOrderList();
      const withPendingOrder = orderList.find(
        (o) =>
          o.status === ORDER_STATUS.PAYMENT_WAITING ||
          o.status === ORDER_STATUS.PAYMENT_FAIL,
      );
      if (withPendingOrder) {
        modalRef.current.open();
        modalRef.current.set({
          elem: (
            <ConfirmModalContent
              title="尚有訂單未付款"
              subtitle="請先完成前一筆未付款訂單"
              onConfirm={async () => {
                await navigate(`/checkout?id=${withPendingOrder.id}`);
              }}
              onCancel={() => {
                modalRef.current.close();
              }}
            />
          ),
        });
      }
    };
    fetch();
  }, [fetchOrderList, modalRef]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Wrapper>
      <Selector
        activeVal={active}
        setActiveVal={setActive}
        buttons={WALLET_TYPE_DISPLAY.map((w) => ({
          value: w.key,
          display: w.title,
        }))}
      />
      {active === WALLET_TYPE.film && <FilmOrderList orderList={orderList} />}
      {active === WALLET_TYPE.meal && <MealOrderList orderList={orderList} />}
      {modalElem}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--topNavBarHeight));
  margin: var(--topNavBarHeight) auto 0 auto;
  align-items: center;
  & > .view {
    width: 100%;
    max-width: var(--contentMaxWith);
    padding: var(--basePadding);
  }
`;

const StyledFilmOrderItem = styled(Card)`
  width: 100%;
  padding: 0;
  position: relative;
  flex-direction: row;
  margin-bottom: 10px;
  & > img {
    width: 92px;
    height: calc(92px * (130 / 92));
    border-radius: 10px;
    object-fit: cover;
  }
  & > .context {
    position: relative;
    width: 100%;
    max-height: 130px;
    padding: 10px 10px 10px 15px;
    & > .title,
    .subtitle {
      text-align: left;
    }
    & > .title {
      font-size: 12px;
    }
    & > .subtitle {
      color: ${Color.black_50};
      margin-bottom: 4px;
    }
    & > .ticket-quantity {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }
`;

export default withLoginRequired(Wallet);
