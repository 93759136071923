import {Color} from '../../constants';
import React from 'react';
import styled from 'styled-components';

const StyledSelector = styled.div`
  width: 100%;
  justify-content: center;
  border-bottom: 2px solid ${Color.black_10};
  display: flex;

  & button {
    background: transparent;
    border: none;
    outline: none;
    width: 30%;
    height: 46px;
    transform: translateY(2px);

    :active {
      opacity: 0.3;
    }
  }

  & .active {
    border-bottom: 2px solid ${Color.orange};
  }
`;
export const Selector = (props) => {
  const {setActiveVal, activeVal, buttons} = props;
  return (
    <StyledSelector className="level-selector">
      {buttons.map((btn, idx) => {
        const active = activeVal === btn.value;
        return (
          <button
            key={idx}
            className={active ? 'active' : ''}
            style={{color: active ? Color.orange : Color.black}}
            onClick={() => {
              setActiveVal(btn.value);
            }}>
            {btn.display}
          </button>
        );
      })}
    </StyledSelector>
  );
};
