import styled from 'styled-components';
import {FlexRowCenterStart, Text12} from '../widget';
import {Breakpoints, Color} from '../constants';
import React from 'react';

export const FilmProperty = ({children = null, value, style = {}}) => (
  <StyledFilmProperty style={style}>
    {children}
    <div
      className="property"
      style={{color: Color.black_50, textAlign: 'left'}}>
      {value}
    </div>
  </StyledFilmProperty>
);
const StyledFilmProperty = styled.div`
  ${FlexRowCenterStart};
  margin-bottom: 4px;
  & > svg {
    margin-right: 5px;
  }
  & > .property {
    font-size: 12px;
    line-height: 1.57;
  }
  @media screen and (min-width: ${Breakpoints.md}px) {
    & > .property {
      font-size: 14px;
      line-height: 1.57;
    }
  }
`;
